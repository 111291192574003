import React from "react"
import { FormattedMessage, Link, useIntl } from "gatsby-plugin-intl"
import { Button, P } from '@bootstrap-styled/v4'
import Content from '../templates/content'
import styled from 'styled-components'
import bp from '../assets/styles/breakpoints'
import SEO from '../components/Navigation/seo'
import Logo from '../../static/assets/icons/rk-logo.inline.svg'

const LogoBox = styled.div`
	padding: 0 2rem;
	margin: 0 auto 2rem;
	max-width: 25rem;
	img, svg{
		max-width: 100%;
		height: auto;
	}
	${bp.down('sm')}{
		max-width: 80%;
		margin-bottom: 1rem;
	}
`;


const IndexPage = () =>{
	const intl = useIntl();
	const title = () => <>
		<LogoBox><Logo /></LogoBox>
		<FormattedMessage id="home.subtitle_1" /> <span className="text-primary"><FormattedMessage id="home.subtitle_2" /></span> <FormattedMessage id="home.subtitle_3" />
	</>;
	return (
		<Content h1={title()}>
			<SEO 
				lang={intl.locale}
				title={intl.formatMessage({ id: "home.seoTitle" })}
				description={intl.formatMessage({ id: "home.seoDesc" })}
			/>
			<div className="mt-3 d-none d-md-block">
				<P className="mb-1 mb-lg-2 d-none">
					<FormattedMessage id="home.desc" />
				</P>
				<Button tag={Link} color="link" to="/o-mnie" className="mr-2 mb-1"><FormattedMessage id="menu.about" /></Button>
				<Button tag={Link} color="link" to="/portfolio" className="mr-2 mb-1"><FormattedMessage id="menu.portfolio" /></Button>
				<Button tag={Link} color="link" to="/kontakt" className="mb-1"><FormattedMessage id="menu.contact" /></Button>
			</div>
		</Content>
	)
}

export default IndexPage
